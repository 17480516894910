<script lang="ts">
  import { GithubIcon, Linkedin, TwitterIcon } from "lucide-svelte";

  import logotype from "$lib/assets/logotype.svg";
  import Chip from "$lib/ui/system/Chip.svelte";
</script>

<footer class="mt-24 border-t border-white/10 bg-black/20 py-8 sm:py-12 px-4">
  <div class="flex justify-between sm:block max-w-screen-xl w-full mx-auto">
    <div class="flex items-center h-fit gap-3">
      <img src={logotype} alt="Modal logo" class="h-[24px]" />
      <span class="whitespace-nowrap text-white/40 text-xs">© 2024</span>
    </div>

    <div
      class="max-w-screen-xl hidden sm:grid sm:grid-cols-2 md:grid-cols-4 gap-12 my-12"
    >
      <div class="flex flex-col gap-3 leading-[1.4]">
        <div class="text-sm font-medium">Use Cases</div>
        <div>
          <a class="link" href="/use-cases/language-models"
            >Language Model Inference</a
          >
        </div>
        <div>
          <a class="link" href="/use-cases/image-video-3d">Image, Video & 3D</a>
        </div>
        <div>
          <a class="link" href="/use-cases/audio">Audio Processing</a>
        </div>
        <div>
          <a class="link" href="/use-cases/fine-tuning">Fine-Tuning</a>
        </div>
        <div>
          <a class="link" href="/use-cases/job-queues"
            >Job Queues & Batch Processing</a
          >
        </div>
        <div>
          <a class="link" href="/use-cases/sandboxes">Sandboxing Code</a>
        </div>
      </div>

      <div class="flex flex-col gap-3 leading-[1.4]">
        <div class="text-sm font-medium">Popular Examples</div>

        <div>
          <a class="link" href="/docs/examples/cloud_bucket_mount_loras"
            >LoRAs Galore!</a
          >
        </div>
        <div>
          <a class="link" href="/docs/examples/vllm_mixtral"
            >Serve Mixtral 8x7B at 100s of tokens per second</a
          >
        </div>
        <div>
          <a class="link" href="/docs/examples/dreambooth_app"
            >Custom Art of Your Pet</a
          >
        </div>
        <div>
          <a class="link" href="/docs/examples/slack-finetune"
            >Replace your CEO with an LLM</a
          >
        </div>
        <div>
          <a class="link" href="/docs/examples/s3_bucket_mount"
            >Analyze Parquet files from S3 with DuckDB</a
          >
        </div>
      </div>
      <div class="flex flex-col gap-3 leading-[1.4]">
        <div class="text-sm font-medium">Resources</div>

        <div><a class="link" href="/docs/guide">Documentation</a></div>
        <div><a class="link" href="/pricing">Pricing</a></div>
        <div><a class="link" href="/slack">Slack Community</a></div>
        <div>
          <a class="link" href="/docs/guide/security">Security & Privacy</a>
        </div>
        <div><a class="link" href="/articles">Articles</a></div>
      </div>
      <div class="flex flex-col gap-3 leading-[1.4]">
        <div class="text-sm font-medium">Company</div>
        <div><a class="link" href="/company">About</a></div>
        <div><a class="link" href="/blog">Blog</a></div>
        <div class="flex items-center gap-2">
          <a class="link" href="/careers">Careers</a><Chip variant="primary"
            >We're hiring!</Chip
          >
        </div>
        <div>
          <a class="link" href="/legal/privacy-policy">Privacy Policy</a>
        </div>
        <div><a class="link" href="/legal/terms">Terms</a></div>
      </div>
    </div>

    <div class="flex space-x-4">
      <a
        href="https://www.linkedin.com/company/modal-labs/"
        target="_blank"
        rel="noreferrer"
        class="social-link"
      >
        <Linkedin size={20} class="stroke-none fill-current" />
      </a>
      <a
        href="https://twitter.com/modal_labs"
        target="_blank"
        rel="noreferrer"
        class="social-link"
      >
        <TwitterIcon size={20} class="stroke-none fill-current" />
      </a>
      <a
        href="https://github.com/modal-labs"
        target="_blank"
        rel="noreferrer"
        class="social-link"
      >
        <GithubIcon size={20} class="stroke-none fill-current" />
      </a>
    </div>
  </div>
</footer>

<style lang="postcss">
  .link {
    @apply text-sm text-white/60 hover:text-white transition-colors;
  }

  .social-link {
    @apply text-white opacity-50 hover:opacity-100 transition-opacity;
  }
</style>
