<script lang="ts">
  import { ArrowRight, ChevronDown, Cpu } from "lucide-svelte";
  import { fade } from "svelte/transition";

  import FineTuningIcon from "$lib/assets/icons/FineTuningIcon.svelte";
  import ModelInferenceIcon from "$lib/assets/icons/ModelInferenceIcon.svelte";
  import SandboxIcon from "$lib/assets/icons/SandboxIcon.svelte";

  let useCasesOpen = false;

  let timerId: NodeJS.Timeout | undefined;
  function handleOpenEvent() {
    if (timerId) {
      clearTimeout(timerId);
      timerId = undefined;
    }
    useCasesOpen = true;
  }

  function handleCloseEvent() {
    if (!timerId) {
      timerId = setTimeout(() => {
        useCasesOpen = false;
      }, 200);
    }
  }
</script>

<div class="font-medium capitalize text-white -mb-1">
  <button
    class="nav-link flex gap-0.5 mb-1"
    on:mouseleave|stopPropagation|self={() => handleCloseEvent()}
    on:blur|stopPropagation|self={() => handleCloseEvent()}
    on:mouseenter|stopPropagation={() => handleOpenEvent()}
    on:focus|stopPropagation={() => handleOpenEvent()}
    on:click|self|stopPropagation={() => handleOpenEvent()}
  >
    <span class="pointer-events-none">Use Cases</span>
    <ChevronDown class="pointer-events-none" size={18} />
  </button>
</div>

{#if useCasesOpen}
  <div
    in:fade|local={{ duration: 100 }}
    out:fade|local={{ delay: 100, duration: 100 }}
    class="absolute top-14 z-10 backdrop-blur"
  >
    <div
      on:mouseleave|stopPropagation|self={() => handleCloseEvent()}
      on:blur|stopPropagation|self={() => handleCloseEvent()}
      on:mouseenter|stopPropagation|self={() => handleOpenEvent()}
      on:focus|stopPropagation|self={() => handleOpenEvent()}
      class="rounded-xl border border-white/10 bg-white/10 w-84 items-start"
    >
      <div class="rounded-xl px-4 py-4 flex flex-col gap-4 bg-black/50">
        <div class="flex flex-col gap-3">
          <div class="flex gap-3 items-center">
            <ModelInferenceIcon size={20} />
            <span class="font-medium capitalize pointer-events-none">
              Model Inference
            </span>
          </div>
          <a
            href="/use-cases/language-models"
            class="px-10 flex gap-2 items-center hover:opacity-60"
            on:click={() => (useCasesOpen = false)}
          >
            <ArrowRight size={18} />
            <span class="font-medium capitalize">Language Models</span>
          </a>
          <a
            href="/use-cases/image-video-3d"
            class="px-10 flex gap-2 items-center hover:opacity-60"
            on:click={() => (useCasesOpen = false)}
          >
            <ArrowRight size={18} />
            <span class="font-medium capitalize">Image, Video & 3D</span>
          </a>
          <a
            href="/use-cases/audio"
            class="px-10 flex gap-2 items-center hover:opacity-60"
            on:click={() => (useCasesOpen = false)}
          >
            <ArrowRight size={18} />
            <span class="font-medium capitalize">Audio</span>
          </a>
        </div>
        <a
          href="/use-cases/fine-tuning"
          class="flex gap-3 items-center hover:opacity-60"
          on:click={() => (useCasesOpen = false)}
        >
          <FineTuningIcon size={20} />
          <span class="font-medium capitalize">Fine-Tuning</span>
        </a>
        <a
          href="/use-cases/job-queues"
          class="flex gap-3 items-center hover:opacity-60"
          on:click={() => (useCasesOpen = false)}
        >
          <Cpu size={20} />
          <span class="font-medium capitalize"
            >Job Queues & Batch Processing</span
          >
        </a>
        <a
          href="/use-cases/sandboxes"
          class="flex gap-3 items-center hover:opacity-60"
          on:click={() => (useCasesOpen = false)}
        >
          <SandboxIcon size={20} />
          <span class="font-medium capitalize">Sandboxed Code Execution</span>
        </a>
      </div>
    </div>
  </div>
{/if}
