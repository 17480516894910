<script lang="ts">
</script>

<div class="fixed w-full h-full flex justify-center items-center isolate -z-10">
  <div>
    <div class="absolute bg-moving bg-animate mx-auto"></div>
  </div>
</div>

<style lang="postcss">
  /* .hide {
    opacity: 0;
  } */
  .bg-moving {
    background-image: url("$lib/assets/marketing/landing-full-gradient.svg");
    background-position: fixed;
    background-repeat: no-repeat;
    background-size: contain;
    width: calc(min(max(100vh, 1000px), 1300px));
    /* Coupling width with view height fixes the background */
    height: calc(min(max(100vh, 1000px), 1500px));
    transform: translate(-54%, -5%) scale(4);
    animation: move 5s ease-in-out infinite alternate;
  }

  @keyframes move {
    0% {
      transform: translate(-68%, -5%) scale(4);
    }
    100% {
      transform: translate(-48%, -0%) scale(4);
    }
  }
</style>
