<script lang="ts">
  import Header from "$lib/ui/common/Header.svelte";
  import MarketingFooter from "$lib/ui/common/MarketingFooter.svelte";
  import PublicNavbar from "$lib/ui/marketing/PublicNavbar.svelte";
  import AnimatedGradient from "$lib/ui/system/AnimatedGradient.svelte";
  import StaticGradient from "$lib/ui/system/StaticGradient.svelte";
  import type { LayoutData } from "./$types";

  export let data: LayoutData;
</script>

<svelte:head>
  <style>
    body {
      background-color: #0c0f0b !important;
    }
  </style>
</svelte:head>

{#if data.useAnimatedGradient}
  <AnimatedGradient />
{:else}
  <StaticGradient />
{/if}
<div class="min-h-screen flex flex-col">
  <Header>
    <PublicNavbar />
  </Header>
  <slot />
  <MarketingFooter />
</div>
