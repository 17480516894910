<script lang="ts">
  export let size = 32;
  let className = "";
  export { className as class };
</script>

<svg
  width={size}
  height={size}
  class={className}
  viewBox="0 0 32 32"
  fill="none"
  stroke="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="terminal-square">
    <path
      id="Vector"
      d="M8 14.5L11 11.5L8 8.5"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      id="Vector_2"
      d="M14 17.5H21"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      id="Vector_3"
      d="M4.33333 2H27.6667C28.9553 2 30 3.04467 30 4.33333V27.6667C30 28.9553 28.9553 30 27.6667 30H4.33333C3.04467 30 2 28.9553 2 27.6667V4.33333C2 3.04467 3.04467 2 4.33333 2Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</svg>
