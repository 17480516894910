import type { LayoutLoad } from "./$types";

function urlMatches(url: URL, prefix: string): boolean {
  return url.pathname === prefix || url.pathname.startsWith(prefix + "/");
}

export const load: LayoutLoad = async ({ url }) => {
  const isGated = urlMatches(url, "/credit-card-gate");
  const useAnimatedGradient = urlMatches(url, "/");
  return {
    isGated,
    useAnimatedGradient,
  };
};
