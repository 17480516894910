<script lang="ts">
  import { browser } from "$app/environment";
  import { page } from "$app/stores";

  import { onDestroy, onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { Hamburger } from "svelte-hamburgers";

  import logotype from "$lib/assets/logotype.svg";
  import LoginSignupButtons from "$lib/ui/common/LoginSignupButtons.svelte";
  import NavLink from "$lib/ui/common/NavLink.svelte";
  import UserMenu from "$lib/ui/common/UserMenu.svelte";
  import { user } from "$lib/user";
  import UseCasesDropdown from "./UseCasesDropdown.svelte";

  let open = false;
  let useCasesOpen = false;

  const links = [
    { to: "/pricing", name: "Pricing" },
    { to: "/company", name: "Company" },
    { to: "/blog", name: "Blog" },
    { to: "/docs", name: "Docs" },
  ];

  const dropdownLinks = [
    { href: "/use-cases/language-models", name: "Language Models" },
    { href: "/use-cases/image-video-3d", name: "Image, Video & 3D" },
    { href: "/use-cases/audio", name: "Audio" },
    { href: "/use-cases/fine-tuning", name: "Fine-Tuning" },
    { href: "/use-cases/job-queues", name: "Job Queues & Batch Processing" },
    { href: "/use-cases/sandboxes", name: "Sandboxed Code Execution" },
  ];

  function handleBodyClick() {
    useCasesOpen = false;
  }

  onMount(() => {
    if (browser) document.body.addEventListener("click", handleBodyClick);
  });
  onDestroy(() => {
    if (browser) document.body.removeEventListener("click", handleBodyClick);
  });

  $: if (browser) document.body.style.overflow = open ? "hidden" : "";
</script>

<div class="relative py-2 px-4 flex justify-center border-b border-white/10">
  <div class="w-full max-w-screen-xl py-1 flex justify-between items-center">
    <a href="/" class="shrink-0 -my-1" on:click={() => (open = false)}>
      <img src={logotype} alt="Modal logo" class="w-[150px]" />
    </a>

    <div class="hidden md:flex items-center gap-2">
      <div class="mx-auto h-full flex items-center gap-2">
        <UseCasesDropdown />
        {#each links as { to, name }}
          <NavLink href={to} {name} />
        {/each}
      </div>

      <svg
        width="2"
        height="26"
        viewBox="0 0 2 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L1 25"
          stroke="white"
          stroke-opacity="0.1"
          stroke-linecap="round"
        />
      </svg>

      <div class="flex items-center">
        {#if $page.data.isGated && $user?.logged_in}
          <UserMenu />
        {:else}
          <LoginSignupButtons />
        {/if}
      </div>
    </div>

    <!-- Mobile animated hamburger menu -->
    <div class="ml-auto flex md:hidden items-center z-10">
      <Hamburger
        bind:open
        --color="white"
        --layer-height="1.5px"
        --layer-width="18px"
        --layer-spacing="5px"
        --padding="4px"
      />
    </div>
    <div
      class="absolute top-full inset-x-0 h-screen overflow-y-auto bg-ground/70 flex flex-col backdrop-blur-xl pb-6 px-6 -z-10 -mt-[58px] opacity-0"
      class:opacity-100={open}
      class:pointer-events-none={!open}
      style:transition="opacity {50 * links.length}ms linear"
    >
      {#if open}
        <div
          class="pt-3 pb-2 text-right mt-[58px]"
          transition:fly|local={{ y: -15, delay: 0 }}
        >
          {#if $page.data.isGated && $user?.logged_in}
            <UserMenu />
          {:else}
            <LoginSignupButtons
              vertical
              on:click={() => ((open = false), (useCasesOpen = false))}
            />
          {/if}
        </div>
      {/if}
      {#if open}
        <button
          class="py-3 text-base text-white font-medium capitalize hover:text-primary flex justify-between items-center"
          on:click|stopPropagation={() => (useCasesOpen = !useCasesOpen)}
          transition:fly|local={{ y: -15, delay: 50 }}
        >
          <span>Use Cases</span>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 6.75L9 11.25L13.5 6.75"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        {#if useCasesOpen}
          <div class="flex flex-col">
            {#each dropdownLinks as { href, name }, i}
              <a
                class="py-3 pl-6 last:pb-6 text-base text-white/80 font-medium capitalize hover:text-primary"
                {href}
                on:click={() => ((open = false), (useCasesOpen = false))}
                transition:fly|local={{ y: -15, delay: 50 * i }}
              >
                {name}
              </a>
            {/each}
          </div>
        {/if}
      {/if}
      {#each open ? links : [] as { to, name }, i}
        <a
          class="py-3 text-base text-white/80 font-medium capitalize hover:text-primary border-t last:border-b border-white/20"
          href={to}
          on:click={() => ((open = false), (useCasesOpen = false))}
          transition:fly|local={{ y: -15, delay: 50 * (i + 2) }}
        >
          {name}
        </a>
      {/each}
    </div>
  </div>
</div>
